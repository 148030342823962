import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";

function App() {
  return (
    <Container fluid className="App">
      <Row>
        <Col xs={12} id={"logo-container"}>
          <header>[logo goes here]</header>
        </Col>
      </Row>

      <Row>
        <Col className={"d-flex justify-content-end pe-3"}>
          <span className={"me-3"}>Sign up</span>
          <span>Login</span>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
